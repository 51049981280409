import React from 'react';
import { Message, Transition, Sticky, Icon } from 'semantic-ui-react';
import { Notifications } from 'dyl-components';
import ICONS from '../../constants/Icons';
import './index.scss';

import ReactDOM from 'react-dom';

const COLORS = 
'red' | 'orange' | 'olive' | 
'green' | 'teal' | 'blue' | 
'violet' | 'purple' | 'pink' |
'san-marino' | 'supernova' | 'atomic-tangerine';

const StyledMessage = ({color=COLORS, children, positive, error, info, opaque}) => {
    if (positive) {
        color = 'success';
    } else if (error) {
        color = 'error';
    } else if (info) {
        color = 'info';
    }
    const solidClassName = opaque ? 'opaque' : 'solid';
    return (
        <Message className={`Message Message--${solidClassName}-${color}`}>
            {children}
        </Message>
    )
}

export const Notification = ({ ref, visible, contextRef, children, status, opaque }) => (
    <div ref={ref} className={`Notification`}>
        <Transition visible={visible} animation='fade' duration='500' >
            <Sticky context={contextRef}>
                <strong>
                <StyledMessage color={status} opaque={opaque}>
                    <Icon name={ICONS[status]} /> {children}
                </StyledMessage>
                </strong>
            </Sticky>
        </Transition>
    </div>
);

export const Alert = ({ status, opaque, message, visible, autoDismiss }) => (
    <Transition visible={visible} duration={500} animation='fade' transitionOnMount unmountOnHide onComplete={autoDismiss}>
        <div className={`Notification`}>
        <Sticky>
            <strong>
            <StyledMessage color={status} opaque={opaque}>
                <Icon name={ICONS[status]} /> {message}
            </StyledMessage>
            </strong>
        </Sticky>
        </div>
    </Transition>
);

export const NotificationInfo = ({ notifications, onOpen, onClear, visible, autoDismiss }) => (
    <Transition visible={visible} duration={500} animation='fade' transitionOnMount unmountOnHide onComplete={autoDismiss}>
        <div className={`NotificationInfo`}>
            <Sticky>
                <Notifications 
                    notifications={notifications}
                    isStatus={false}
                    isTime={false}
                    isBorder
                    onOpen={onOpen}
                    onClear={onClear}
                />
            </Sticky>
        </div>
    </Transition>
);

class DismissibleAlert extends React.Component {
    state = {
        visible: this.props.visible,
        notifications: this.props.notifications || []
    }

    dismiss() {
        if (this.props.selfDismissing) {
            setTimeout(() => {
                this.setState({ visible: false });
            }, (this.props.timeout || 2000));
        }
    }

    clearNotification (id) {
        let notifications = this.state.notifications;
        const indexToRemove = notifications.findIndex(notification => notification.id === id);
        if(notifications.length > 1 && indexToRemove > -1){
            notifications.splice(indexToRemove, 1);
            this.setState({ visible: true, notifications: notifications});
        } else {
            this.setState({ visible: false });
        }
        
      
    }
    render() {
        return (
            this.props.isNotifcationAlert ?
                <NotificationInfo
                    {...this.props}
                    notifications={this.state.notifications}
                    visible={this.state.visible}
                    autoDismiss={this.dismiss.bind(this)}
                    onClear={this.clearNotification.bind(this)}
                    onOpen={this.props.onOpen}
                />
                :
                <Alert 
                    {...this.props}
                    visible={this.state.visible}
                    autoDismiss={this.dismiss.bind(this)}
                />
        )
    }
}

Notification.alert = (message, status, selfDismissing = true, props) => {
    props = Object.assign({ message, status, selfDismissing }, props);
    return next(props);
}

NotificationInfo.alert = (notifications, onOpen, selfDismissing = true, props) => {
    props = Object.assign({ notifications, onOpen, selfDismissing, isNotifcationAlert: true, timeout: 5000 }, props);
    return next(props);
}

function next(props) {
    return new Promise((resolve, reject) => {
        const div = document.createElement('div');

        document.body.appendChild(div);
      
        props.visible = true;

        const component = React.createElement(DismissibleAlert, Object.assign({}, props, {
            promise: { resolve, reject },
            willUnmount: () => {
                ReactDOM.unmountComponentAtNode(div);
                document.body.removeChild(div);
            }
        }));

        ReactDOM.render(component, div);
    })
}